
import { create, track } from '~/utils/analytics';
import countriesAndRegions from 'mixins/countries-and-regions/countriesAndRegions';
import { mapGetters } from 'vuex';
import CmsError from '../components/cms-error';

export default {
  name: 'marketo-form',
  components: { CmsError },
  mixins: [countriesAndRegions],
  props: {
    formId: {
      type: String,
      required: true,
    },
    hideForm: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideLoader: {
      type: Boolean,
      default: false,
    },
    disableLead: {
      type: Boolean,
      default: false,
    },
    errorMsgUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formElementId: 'mktoForm_' + this.formId,
      munchkinId: '503-OWD-828',
      baseUrl: '//insight.deputy.com',
      uniqueKey: Math.floor(Math.random() * 1000),
      isMounted: false,
      showErrorMsg: false,
      redirectUrls: [
        {
          region: 'AMER',
          url: 'https://deputy.zoom.us/webinar/register/WN_gLVGpyF0Q0CfaUmCqCCB3A',
        },
        {
          region: 'APAC',
          url: 'https://deputy.zoom.us/webinar/register/WN_EnStJpSZTzWk0jjVNPRQcw',
        },
        {
          region: 'EMEA',
          url: 'https://deputy.zoom.us/webinar/register/WN_gMDZ5IA8QHyTtnIE4yCG9Q',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isMarketoFormLoaded: 'form/isMarketoFormLoaded',
    }),
  },
  beforeMount() {
    // check every 5ms if the MKtoForms2 Instance is set
    // Why? the script loads in the head and take few millisecond to be fetched
    // this component might be mounted before
    let maxTime = 0;
    const marketoCheck = setInterval(() => {
      if (typeof MktoForms2 !== 'undefined') {
        clearInterval(marketoCheck);
        this.initialiseMarketoForm();
      }
      maxTime += 5;
      if (maxTime >= 3000) {
        // the marketo script did probably not load correctly (404|403|Corrs)
        // let's make sure we stop the interval to avoid any infinite loop
        this.showErrorMsg = true;
        clearInterval(marketoCheck);
      }
    }, 5);
  },
  mounted() {
    window.setTimeout(() => {
      if (!this.isMounted) {
        return (this.showErrorMsg = true);
      }
    }, 5000);

    this.checkSimpleDTOAndPrefillForm();
  },
  methods: {
    checkSimpleDTOAndPrefillForm() {
      let maxTime = 0;
      const intervalCheck = setInterval(() => {
        if (typeof SimpleDTO !== 'undefined') {
          clearInterval(intervalCheck);
          this.prefillForm();
        }
        maxTime += 1;
        if (maxTime >= 10) {
          console.log('SimpleDTO not loaded');
          clearInterval(intervalCheck);
        }
      }, 1000);
    },
    prefillForm() {
      const DTO = new SimpleDTO({
        mode: 'receive',
        transport: 'message',
        messageSource: 'https://insight.deputy.com',
        messageTarget: [
          'https://www.deputy.com',
          'https://wpstage.deputec.com',
        ],
        dataSrc: 'https://insight.deputy.com/dtp-204.html',
        cb: function (instance, mktoFields) {
          MktoForms2.whenReady(function (form) {
            form.setValuesCoerced(mktoFields);
          });

          try {
            DTO.cleanup();
          } catch (error) {
            console.log(error);
          }
        },
      });
    },
    redirectToSelfServeUrl(url) {
      setTimeout(function () {
        window.top.location = url;
      }, 250);
    },
    getRegionUrlFromCountry(countryName) {
      const country = this.getCountryData(countryName);
      const redirectUrl = this.redirectUrls.find(
        redirectUrl => redirectUrl.region === country?.region
      );
      return (
        redirectUrl?.url ??
        this.redirectUrls.find(url => url.region === 'AMER').url
      );
    },
    /**
     * Checks the data from the submitted form and decides whether we need to
     * redirect the user or just continue with Chilipiper.
     *
     * @see: https://deputy.atlassian.net/browse/WOK-3311 (rules)
     *
     * @param {String} numEmployees the number of employees selected in the form (range)
     * @param {String} countryName the name of the country selected in the form
     * @return {String|null} the url to redirect the user to or null if no redirect needed
     */
    resolveRedirectUrl(numEmployees, countryName) {
      const { region } = this.getCountryData(countryName) || {};
      const isCoreCountry = this.isCoreCountry(countryName);
      if (
        numEmployees === '1 - 15' ||
        numEmployees === '1 - 25' ||
        (numEmployees === '16 - 49' && !isCoreCountry) ||
        (numEmployees === '26 - 49' && !isCoreCountry) ||
        (region === 'EMEA' && numEmployees === '16 - 49') ||
        (region === 'EMEA' && numEmployees === '26 - 49') ||
        (region === 'EMEA' && numEmployees === '50 - 249' && !isCoreCountry)
      ) {
        return this.getRegionUrlFromCountry(countryName);
      }
      return null;
    },
    onClickSubmitBtn() {
      this.$emit('click-cta');
    },
    initialiseMarketoForm() {
      // is the Marketo form already loaded on the page?
      if (!this.isMarketoFormLoaded(this.formId)) {
        // first time, we add the ID to the store
        this.$store.commit('form/formLoaded', this.formId);
      } else {
        // the form is already on the page, we will change the previous ID of any similar marketo form
        const forms = document.querySelectorAll(`#${this.formElementId}`);
        forms.forEach(form => {
          if (form.length > 0) {
            form.setAttribute('id', `${this.formElementId}-${this.uniqueKey}`);
          }
        });
      }
      window.MktoForms2.onFormRender(form => {
        if (form) {
          const formEl = form.getFormElem()[0];
          // fields which can be styled
          const placeholderableTypes = {
            INPUT: [
              'text',
              'search',
              'tel',
              'url',
              'email',
              'password',
              'number',
              'radio',
            ],
            SELECT: ['select-one'],
          };
          const submitBtn = formEl.querySelector('button[type="submit"]');

          function managePlaceholders(event) {
            const currentValues = form.getValues();
            const fieldNames = Object.keys(currentValues);
            // we will loop through all our fields to map input id with label name
            fieldNames
              .map(function (fieldName) {
                // get input field id
                const fieldEl = formEl.querySelector(
                  "[id='" + fieldName + "']"
                );
                // matched label from input id
                const labelEl =
                  fieldEl &&
                  formEl.querySelector("label[for='" + fieldEl.id + "']");

                return {
                  fieldEl: fieldEl,
                  labelEl: labelEl,
                  isValued:
                    Boolean(currentValues[fieldName]) ||
                    event?.target?.name === fieldName,
                };
              })
              .filter(function (labelDesc) {
                // label exists + it's part of the allowed field
                return (
                  labelDesc.labelEl &&
                  Array.isArray(
                    placeholderableTypes[labelDesc.fieldEl.nodeName]
                  ) &&
                  placeholderableTypes[labelDesc.fieldEl.nodeName].some(
                    function (type) {
                      // the type is matching
                      return labelDesc.fieldEl.type === type;
                    }
                  )
                );
              })
              .forEach(function (labelDesc) {
                // we add the attribute to the label which will be used for styling
                labelDesc.labelEl.setAttribute(
                  'data-for-placeholder-hidden',
                  [labelDesc.isValued, 'prefilled'].join('-')
                );
              });
          }

          formEl.addEventListener('focusin', managePlaceholders);
          submitBtn.onclick = this.onClickSubmitBtn;
          managePlaceholders();
        }
      });
      window.MktoForms2.loadForm(
        this.baseUrl,
        this.munchkinId,
        this.formId,
        form => {
          /*
          // START RADIO CHECKLIST MANIPULATION
          // The following is affecting all forms
          // If we want to run only on a specific form we can just check for the `formId`
          */
          const formElem = form.getFormElem();
          // let find all the radio list in the form
          const radioList = formElem.find('.mktoRadioList');
          radioList.map((key, node) => {
            // we get all the inputs (here it will be all the input=radio)
            // as we are in the radioList
            const inputs = node.querySelectorAll('input');
            // What's happening below?
            // Marketo radioList are formatted with a specific way
            // <div> <input><label> <input><label> <input><label> <div>
            // This is a hassle to add some styling
            // the loop bellow will re-structure the checklist to have every input+ label wrapped in a div
            // <div> <div><input><label></div> <div><input><label></div> <div><input><label></div> </>div>
            inputs.forEach(input => {
              const newNode = document.createElement('div');
              newNode.classList.add('radioButton--custom');
              newNode.setAttribute('role', 'radio');
              // we get the immediate element
              const nextSibling = input.nextSibling;
              newNode.appendChild(input);
              newNode.appendChild(nextSibling);
              node.appendChild(newNode);
            });
          });
          /* END RADIO CHECKLIST MANIPULATION */

          // form is loaded
          this.isMounted = true;
          form.onSubmit(() => {
            const vals = form.vals();

            const data = {
              category: 'Click',
              page: document.location.href,
              ...vals,
            };

            // Send data event to Segment and GTM
            // We don't want to trigger leads on Book a consultation form or any hidden marketo form
            // triggered alongside the signup. You should set ():lead=false) on those marketo forms
            if (!this.disableLead) {
              const startedEvent = create(data);
              track('LEAD_SUBMITTED', startedEvent);
            }

            // Additional tracking for Download form
            if (this.formId === '2215' || this.formId === '1008') {
              const startedEvent = create(data);
              track('DOWNLOAD_FORM_SUBMITTED', startedEvent);
            }

            // The form is Book a consultation we need to submit to Chilipiper
            if (this.formId === '1181') {
              if (typeof window.dataLayer !== 'undefined') {
                data.ATmeta = window.ATmeta;
                window.dataLayer.push({
                  event: 'deputy.bookAConsultation',
                });
              }

              const redirectUrl = this.resolveRedirectUrl(
                vals?.How_many_employees_do_you_have__c,
                vals?.Country
              );
              if (redirectUrl) {
                this.redirectToSelfServeUrl(redirectUrl);
              } else {
                window.ChiliPiper.submit(
                  'deputy',
                  'inbound_router_web_bookademo2',
                  {
                    lead: vals,
                    domain: 'deputy',
                    title: 'Thanks! What time works best for a quick call?',
                    titleStyle: '"Lato" 22px #332a5d',
                  }
                );
              }
            }
            // Sample data returned by the form:
            /*
            {
              FirstName: 'Test',
              LastName: 'Test',
              Email: 'test@test.com',
              Company: 'Test',
              Phone: '123456789',
              Industry: 'Aged care',
              Country: 'Australia',
              State: 'NSW',
              How_many_employees_do_you_have__c: '50 - 249',
              LeadSource: 'Web Request',
              Lead_Source_1_Original__c: 'Marketing',
              Lead_Source_2_Original__c: 'Email/Website',
              Lead_Source_3_Original__c: 'Contact Us Form',
              Lead_Source_1_Latest__c: 'Marketing',
              Lead_Source_2_Latest__c: 'Email/Website',
              Lead_Source_3_Latest__c: 'Contact Us Form',
              UTM_Campaign_Original__c: '',
              UTM_Medium_Original__c: '',
              UTM_Source_Original__c: '',
              UTM_Content_Original__c: '',
              UTM_Term_Keyword_Original__c: '',
              UTM_Campaign_Latest__c: '',
              UTM_Medium_Latest__c: '',
              UTM_Source_Latest__c: '',
              UTM_Content_Latest__c: '',
              UTM_Term_Keyword_Latest__c: '',
              mktoOptIn: 'yes',
              mktoOptInDateTime: '{{system.dateTime}}',
              mktoOptInSource: '',
              formid: '1181',
              munchkinId: '503-OWD-828',
            }
            */
          });
          form.onSuccess(() => {
            // eslint-disable-next-line no-console
            console.log('marketo-form-success');
            if (
              this.formId === '1181' ||
              this.formId === this.$store.state.form.marketoSignupFormId
            ) {
              // returning false will prevent the form from being redirected
              return false;
            }
          });

          /*
            Fix duplicate copies of same form id. It's a marketo form known issue by change id attribute
            Then use data-formId atrribute instead, marketo form will handle the rest.
            https://nation.marketo.com/t5/product-discussions/multiple-copies-of-same-form-on-a-page-problems/td-p/126177
          */
          const forms = document.querySelectorAll(`#${this.formElementId}`);
          if (forms.length > 1) {
            forms.forEach((form, idx) => {
              form.setAttribute('id', `${this.formElementId}-${idx}`);
            });
          }
        }
      );
    },
  },
};
