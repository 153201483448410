
export default {
  name: 'g-table',
  props: {
    tbody: {
      type: Array,
      default: () => {
        return [];
      },
    },
    thead: {
      type: Array,
      default: () => {
        return [];
      },
    },
    slim: {
      type: Boolean,
      default: false,
    },
  },
};
