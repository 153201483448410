
import GNavigation from '../goose/g-navigation';
import { mapGetters } from 'vuex';

export default {
  name: 'menu-navigation',
  components: {
    GNavigation,
  },
  data() {
    return {
      menuDesktop: require(`../../lib/storyblok-local-json/menu-desktop-${this.$store.state.region}.json`),
      menuMobile: require(`../../lib/storyblok-local-json/menu-mobile-${this.$store.state.region}.json`),
      headerClass: this.$store.state.menu.menuHeader,
      headerText: this.$store.state.menu.headerText,
      navContainerClass: 'nav-blur',
    };
  },
  computed: {
    ...mapGetters({
      getHomeUrl: 'getHomeUrl',
      getCustomer: 'getCustomer',
    }),
    desktopContents() {
      return this.menuDesktop.content.menu_parents;
    },
    desktopButtons() {
      return process.client && this.$store.state.isPaidCustomer
        ? this.reorderLoginAndSupportButtons(
            this.addContactSupportButton(this.menuDesktop.content.buttons)
          )
        : this.menuDesktop.content.buttons;
    },
    mobileContents() {
      return this.menuMobile.content.menu_parents;
    },
    mobileButtons() {
      return process.client && this.$store.state.isPaidCustomer
        ? this.addContactSupportButton(this.menuMobile.content.buttons)
        : this.menuMobile.content.buttons;
    },
  },
  watch: {
    getCustomer: function () {
      this.removeNavBlur();
    },
  },
  mounted() {
    // make sure we remove the blurry effect on components even if that has
    // not been resolved yet
    let counter = 0;
    const checkIfNavPageLoaded = setInterval(() => {
      // up to 1s 200x600ms then we clear the blur if still not resolved
      if (counter <= 200) {
        if (window.isPageLoaded) {
          this.removeNavBlur();
          clearInterval(checkIfNavPageLoaded);
        }
        counter++;
      } else {
        this.removeNavBlur();
        clearInterval(checkIfNavPageLoaded);
      }
    }, 600);
  },
  methods: {
    // remove the blurry effect from the navigation elements (when used on
    // experiments or client side manipulations
    removeNavBlur() {
      this.navContainerClass = null;
      const el = this.$refs.navContainer;
      if (el) {
        el.classList.remove('nav-blur');
      }
    },
    // add the `contact support` button replacing the `contact sales` one when
    // we identify the user as a Deputy customer
    addContactSupportButton(buttons) {
      const contactSupportUrl =
        'https://help.deputy.com/hc/en-au/articles/4657553285391-Get-help-and-request-support-from-Deputy';
      const contactSupportBtn = {
        id: '',
        url: contactSupportUrl,
        linktype: 'url',
        fieldtype: 'multilink',
        cached_url: contactSupportUrl,
      };

      return buttons.map(btn => {
        return btn.button_target === '#bookADemo'
          ? {
              ...btn,
              button_link: contactSupportBtn,
              button_target: '',
              button_text: 'Contact Support',
              // manually creating a `cta_id` so we can track it
              // (see `g-navigation` component)
              cta_id: 'contact-support',
            }
          : { ...btn };
      });
    },
    reorderLoginAndSupportButtons(buttons) {
      return buttons
        .map(btn => {
          if (btn.cta_id === 'contact-support') {
            return { ...btn, color: 'text' };
          }

          if (btn.button_link.url.includes('login')) {
            return { ...btn, color: 'white' };
          }

          return btn;
        })
        .sort((a, b) => {
          if (
            a.cta_id === 'contact-support' &&
            b.button_link.url.includes('login')
          ) {
            return -1;
          }
          return 0;
        });
    },
  },
};
